.show-error{
    display: inherit;
}

.hide-error{
    display: none!important;
}
.Align {
    padding: 2rem;
    margin-top: 0;
    margin-left: 30px;
}

body {
  font-family: "Titillium Web", sans-serif;
}
.main-content {
  background: var(--bg-light);
}
.breadcrumb-container {
  background: var(--white);
}
.navbar-nav .dropdown-menu {
  min-width: 320px;
}
.user-dropdown .dropdown-toggle::after {
  bottom: -5px;
  left: 50%;
  margin-left: 10px;
  background: white;
  border-radius: 50%;
  padding: 2px 4px;
  width: 24px;
  height: 24px;
  border: 1px solid var(--tertiary);
}
.user-dropdown .dropdown-toggle:hover::after {
  color: var(--primary);
}
.user-dropdown .dropdown-menu .dropdown-item {
  padding: 0.8rem 1rem;
}


.uploaded-doc {
    border: 1px solid #ddd;
    padding: 1rem;
    text-align: left;
    border-radius: 5px;
    background: #eaf5f9;
    height: 100%;
}
.uploaded-doc span {
    color: var(--secondary);
    display: block;
    text-align: left;
    border-bottom: 1px solid #ddd;
    margin: 0 -1rem;
    padding: 0 1rem;
    padding-bottom: 1rem;
}
.uploaded-doc span label {
    color: #333;
    font-weight: 600;
    display: block;
    margin-bottom: 0;
}
.uploaded-doc span strong {
    text-align: left;
    border-bottom: 1px dashed;
    font-weight: 400;
    margin-bottom: 0.5rem;
    display: inline-block;
}
.uploaded-doc label.custom-control-label {
    font-size: 0.9rem;
    margin: 0.5rem 0;
    width: 100%;
}
.uploaded-doc .custom-switch .custom-control-label::before {
    left: auto;
    right: -2.25rem;
}

.uploaded-doc .custom-switch {
    padding-left: 0;
    padding-right: 2.5rem;
}

.uploaded-doc .custom-switch .custom-control-label::after {
    left: auto;
    right: calc(-1rem - 7px);
}



.recipients-card .form-group {
    position: relative;
    width: calc(33.3% - 10px);
    margin-right: 10px;
    margin-bottom: 0.5rem;
}

.recipients-card .form-group .form-control{ margin: 0;}
.card-body div#myInputautocomplete-list {
    width: 100%;
    background: #ebf2f5;
    border-radius: 0px 0 10px 10px;
    position: absolute;
    top: 44px;
    border: 1px solid #c3d1d7;
}

.recipients-card .card-header {
    text-align: left;
    padding: 0.75rem 1.25rem;
}

.recipients-card .card-header h4.card-title {
    margin: 0;
}

.recipients-card .card-header h4.card-title span {
    background: #ddd;
    width: 34px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    height: 34px;
    line-height: 34px;
    vertical-align: middle;
    margin-right: 10px;
}

.recipients-card .card-body {
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.recipients-card .card {
    margin-bottom: 0.5rem;
    position: relative;
    margin-left: 60px;
    padding-left: 2rem;
}
.recipients-card .card .card-order {
    position: absolute;
    left: -60px;
    width: 50px;
    top: 50%;
    margin-top: -22px;
}

.ml-80 {
    margin-left: 60px;
}

.card-dragable {
    position: absolute;
    left: 0;
    width: 24px;
    height: 100%;
    display: flex;
    color: rgb(35 92 118 / 60%);
    align-items: center;
    justify-content: center;
    background: rgb(35 92 118 / 15%);
}

.card-dragable:hover {
    cursor: move;
}


.card .dropdown-item{ font-size: 0.9rem;}

*, ::after, ::before {
    box-sizing: border-box;
}

.fa-user:before {
    content: "\f007";
}

card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}





